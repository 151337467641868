<template>
  <div>
    <Datatable
      ref="salesTable"
      :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.SEARCHINVOICES"
      :datatable-attach-search="datatableAttachSearch"
      :searchPayload="searchPayload"
      :datatable-headers="datatableHeaders"
      :excel-columns="excelColumns"
      :excel-file-name="$t('accounting.lang_invoices')"
      show-display-buttons
      @displayEntry="displayEntry"
      pdf-file-name="invoices"
      show-pdf
      show-selected-pdf
      @downloadSelectedPdf="downloadSelected"
      :pdf-selected-loading="loadingSelectedPDF"
    >
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
    </Datatable>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
      />
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import FileSaver from "file-saver";
import mixin from "../../mixins/KeyboardMixIns";
import { mapGetters } from "vuex";
import Datatable from "../datatable/Datatable";

export default {
  name: "SearchInvoice",

  components: {
    Datatable,
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      dailyReports: [],
      departments: [],
      paymentTypes: [],
      loadingSelectedPDF: false,
      pdfSelectLimit: 15, // the max number of selected invoices to export

      datatableHeaders: [
        {
          text: this.$t("generic.lang_id"),
          align: "left",
          value: "id",
          hide: true,
        },
        { text: this.$t("generic.lang_id"), value: "ident", sort: "desc" },
        {
          text:
            this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          value: "date",
        },
        { text: this.$t("generic.lang_seller_id"), value: "sellerid" },
        { text: this.$t("generic.lang_customer"), value: "cust" },
        { text: this.$t("erp.lang_totalsolditems"), value: "saleItems" },
        { text: this.$t("erp.lang_ware_total"), value: "total" },
        { text: this.$t("accounting.lang_paymenthod"), value: "paymentType" },
        {
          text: this.$t("generic.lang_TableNo"),
          value: "tableNo",
          hide: false,
        },
        { text: this.$t("generic.lang_parMaison"), value: "parMaison" },
        { text: this.$t("generic.lang_cashierID"), value: "cashierID" },
        { text: this.$t("generic.lang_receipttype"), value: "type" },
      ],
      datatableAttachSearch: [
        {
          text: this.$t("accounting.lang_dailyReportStart"),
          value: "start_date",
          searchType: "date",
          searchCol: 4,
        },
        {
          text: this.$t("accounting.lang_dailyReportEnd"),
          value: "end_date",
          searchType: "date",
          searchCol: 4,
        },
        {
          text: this.$t("accounting.lang_dailyReportID"),
          value: "daily_report_id",
          searchType: "singleselect",
          searchCol: 4,
        },
        {
          text: this.$t("customers.lang_customerDepartments"),
          value: "department",
          searchType: "singleselect",
          searchCol: 4,
        },
        {
          text: this.$t("generic.lang_cashierID"),
          value: "cashierId",
          searchType: "singleselect",
          searchCol: 4,
        },
        {
          text: this.$t("accounting.lang_paymenthod"),
          value: "paymentTypeID",
          searchType: "singleselect",
          searchCol: 4,
        },
      ],
      excelColumns: [
        { label: this.$t("generic.lang_id"), field: "ident" },
        {
          label:
            this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          field: "date",
        },
        { label: this.$t("generic.lang_seller_id"), field: "sellerid" },
        { label: this.$t("generic.lang_customer"), field: "cust" },
        { label: this.$t("erp.lang_totalsolditems"), field: "saleItems" },
        { label: this.$t("erp.lang_ware_total"), field: "total" },
        { label: this.$t("accounting.lang_paymenthod"), field: "paymentType" },
        { label: this.$t("generic.lang_TableNo"), field: "tableNo" },
        { label: this.$t("generic.lang_parMaison"), field: "parMaison" },
        { label: this.$t("generic.lang_cashierID"), field: "cashierID" },
        { label: this.$t("generic.lang_receipttype"), field: "type" },
      ],
      searchPayload: {},
    };
  },
  computed: {
    ...mapGetters({
      cashierIDs: "cashierIDs/cashiers",
    }),
    filteredCashierIDs() {
      return this.cashierIDs.map((item) => ({
        name:
          this.$t("generic.lang_kasse") +
          " " +
          item.id +
          (item.aliasName ? ` (${item.aliasName}) ` : ""),
        id: item.id,
      }));
    },
  },

  async mounted() {
    await this.loadDailyReports();
    await this.loadDepartments();
    await this.loadPaymentTypes();
    await this.getSearchPayload();
  },

  methods: {
    async getSearchPayload() {
      let data = {};

      //dailyReports
      data["daily_report_id"] = this.dailyReports
        .map((dailyReport) => {
          return {
            id: dailyReport.id,
            name: dailyReport.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      data["cashierId"] = this.filteredCashierIDs;

      //departments
      data["department"] = this.departments
        .map((department) => {
          return {
            id: department.id,
            name: department.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      //paymentTypes
      data["paymentTypeID"] = this.paymentTypes
        .map((paymentType) => {
          return {
            id: paymentType.id,
            name: paymentType.name,
          };
        })
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      this.searchPayload = data;
    },
    loadDailyReports() {
      return this.axios
        .post(ENDPOINTS.ACCOUNTING.DAILYREPORT.REPORTS)
        .then((res) => {
          if (res.data.STATUS == "SUCCESS") {
            let data = res.data;
            let reportOptions = [];

            if (
              data.reports &&
              data.reports != null &&
              data.reports.length > 0
            ) {
              for (let i = 0; i < data.reports.length; i++) {
                let item = data.reports[i];
                reportOptions.push({ name: item[1], id: item[0] });
              }
            }
            this.dailyReports = reportOptions;
          }
        });
    },
    loadDepartments() {
      return this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL)
        .then((res) => {
          if (Array.isArray(res.data.results))
            this.departments = res.data.results.map((dp) => ({
              id: dp.id,
              name: dp.departmentName,
            }));
        });
    },
    loadPaymentTypes() {
      return this.axios
        .post(ENDPOINTS.ACCOUNTING.SEARCHINVOICE.GET_PAYMENTTYPES)
        .then((res) => {
          if (res.data.status)
            Object.entries(res.data.payment_types).forEach((row) => {
              this.paymentTypes.push({
                id: row[0],
                name: row[1],
              });
            });
        });
    },
    displayEntry(entry) {
      this.uuid = entry.id;
      this.text = entry.name;
      this.$router.push({
        name: "accounting.DisplayInvoice",
        params: { id: this.uuid },
      });
    },
    downloadSelected(ids) {
      if (ids && ids.length > this.pdfSelectLimit) {
        Events.$emit("showSnackbar", {
          message:
            this.$t("generic.lang_downloadLimit") +
            " " +
            this.pdfSelectLimit +
            " " +
            this.$t("generic.lang_selected") +
            " " +
            ids.length,
          color: "warning",
        });
        this.$refs.salesTable.resetSelectedRows();
        return;
      }
      this.loadingSelectedPDF = true;

      this.axios
        .post(
          "get/accounting/bulkInvoiceDataPDF/",
          {
            invoiceUUIDS: ids,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          const blobContent = new Blob([res.data], { type: "application/pdf" });
          //DOWNLOAD INVOICE AS PDF
          FileSaver.saveAs(
            new Blob([res.data], {
              type: "application/pdf",
            }),
            "Invoice" + ".pdf"
          );
          this.$refs.salesTable.resetSelectedRows();
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingSelectedPDF = false;
        });
    },
  },
};
</script>
